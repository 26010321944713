import React from 'react'

function GrievanceScreen() {
  return (
      <div class="form_main_grid">
        <div class="form_comp">
          <h1>Grivance Form</h1>
          <h4>For any type query you can directly email to us on <a href="mailto:gpcdamoh@gmail.com?Subject=GRIEVANCE">gpcdamoh@gmail.com</a></h4>
        </div>
        <div class="form_comp">
          
          
        </div>
      </div>
  )
}

export default GrievanceScreen