import React from 'react';
import logo from "../assets/logo.png";
import { useHistory } from "react-router-dom";


function HeaderComp() {

  const history = useHistory()

  const handleClick = (route) => {
    history.push("/" + route)
  }

  return (
    <div className="header">

      <div className="header_left" onClick={()=>history.push("/")}>
        <img src={logo} alt='' />
        <div className='header_left_content'>
          <h1>Government Polytechnic College</h1>
          <h3>Jabalpur Rd, Aam Choumpra, Madhya Pradesh 470661</h3>
        </div>
      </div>

      <div className="header_right">
        <h5 onClick={() => handleClick("")}>Home</h5>
        <h5 onClick={() => handleClick("admission")}>Admission</h5>

        <h5 onClick={() => handleClick("facilities")}>Facilities</h5>
        <h5 onClick={() => handleClick("department")}>Department</h5>
        <h5 onClick={() => handleClick("syllabus")}>Syllabus</h5>
        <h5 onClick={() => handleClick("notifications")}>Notifications</h5>


        <h5 onClick={() => handleClick("videos")}>Video Lectures</h5>
        <h5 onClick={() => handleClick("grievance")}>Grievance</h5>
      </div>

    </div>
  )
}

export default HeaderComp