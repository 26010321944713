import React from 'react'
import BannerComp from '../comp/BannerComp'
import AboutComp from '../comp/AboutComp'
import GalleryComp from '../comp/GalleryComp'
import FooterComp from '../comp/FooterComp'

function HomeScreen() {
  return (
    <div>
       <BannerComp />
       <AboutComp />
       <GalleryComp />
      
    </div>
  )
}

export default HomeScreen