import React from 'react'
import FacilitiesComp from '../comp/FacilitiesComp'

function FacilitiesScreen() {

    const facilitiesArray = [
        {
            "img":"https://firebasestorage.googleapis.com/v0/b/gpcdamoh-5eeda.appspot.com/o/documents%2F1603196049761?alt=media&token=60f6a3ae-3534-4933-bade-9a9a2514de40",
            "title":"Library",
            "text":"A library is a curated collection of sources of information and similar resources, selected by experts and made accessible to a defined community for reference or borrowing, often in a quiet environment conducive to study. It provides physical or digital access to material, and may be a physical location or a virtual space, or both. A library's collection can include books, periodicals, newspapers, manuscripts, films, maps, prints, documents, microform, CDs, cassettes, videotapes, DVDs, Blu-ray Discs, e-books, audiobooks, databases, table games, video games and other formats. Libraries range widely in size up to millions of items. The word for 'library' in many modern languages is derived from Ancient Greek βιβλιοθήκη (bibliothēkē), originally meaning bookcase, via Latin bibliotheca."
        },
        {
            "img":"https://firebasestorage.googleapis.com/v0/b/gpcdamoh-5eeda.appspot.com/o/documents%2F1603195347208?alt=media&token=2f52a2dd-d090-42f1-b77c-853bf6bed0f7",
            "title":"NSS",
            "text":"The National Service Scheme (NSS) is an Indian government-sponsored public service program conducted by the Ministry of Youth Affairs[1] and Sports of the Government of India. Popularly known as NSS, the scheme was launched in Gandhiji's Centenary year in 1969. Aimed at developing student's personality through community service, NSS is a voluntary association of young people in Colleges, Universities and at +2 level working for a campus-community (esp. Villages) linkage."
        },
        {
            "img":"https://firebasestorage.googleapis.com/v0/b/gpcdamoh-5eeda.appspot.com/o/documents%2F1602859237812?alt=media&token=9ddf5779-230a-4a47-bc8f-7b0a1eb91eec",
            "title":"NCC",
            "text":"The National Cadet Corps is the youth wing of Armed Forces with its headquarters at New Delhi, Delhi, India. It is open to school and college students on voluntary basis. National Cadet Corps is a Tri-Services Organisation, comprising the Army, Navy and Air Wing, engaged in grooming the youth of the country into disciplined and patriotic citizens. The National Cadet Corps in India is a voluntary organisation which recruits cadets from high schools, higher secondary, colleges and universities all over India. The Cadets are given basic military training in small arms and parades. The officers and cadets have no liability for active military service once they complete their course."
        },
    ]

    return (
        <div>
            {
                facilitiesArray?.map((item,index)=>{
                    return(
                        <FacilitiesComp item={item}/>
                    )
                })
            }
        </div>
    )
}

export default FacilitiesScreen