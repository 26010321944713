import React, { useState, useEffect } from 'react'
import firebase  from '../base'
function DepartmentComp({branchName, items }) {

  const branch = {
    "cs":"Computer Science Engineering",
    "ee":"Electrical Engineering",
    "civil":"Civil Engineering",
    "mech":"Mechanical Engineering",
    "et":"Electonics Telecommunication"
  }

  return (
    <div className='department_comp'>
      <div className='syllabus_head'>
        <h4>{branch[branchName] }</h4>
        <h4></h4>
      </div>

      <div className='department_content'>
        {
          items?.map((item, index) => {
            return (
              <div className='department_rows'>
                {console.log(item)}
                <img src={item?.profile_img} alt="" />
                <p>Name : {item?.name}</p>
                <p>Designation : {item?.designation}</p>
                <p>Email : {item?.email}</p>
                <p>Contact : {item?.contact}</p>

              </div>
            )
          })
        }

      </div>

    </div>
  )
}



function DepartmentScreen() {

  const [list, setlist] = useState([])
  const [loading, setloading] = useState(true)

  useEffect(() => {

    const subscriber = firebase.firestore()
      .collection('teachers')
      .onSnapshot(querySnapshot => {
        const list_ = [];
        querySnapshot.forEach(doc => {

          list_.push({
            ...doc.data(),
            key: doc.id
          });
        });

        setlist(list_);
        setloading(false);
      });

    // Unsubscribe from blogs when no longer in use
    return () => subscriber();
  }, []);


  const getList = (tag) => {
    return list.filter(obj => obj.department === tag);
  }

  return (
    <div className='department'>
      <div className='dept_comp'>
        <DepartmentComp branchName={"civil"}  items={getList("civil")} />
      </div>
      <div className='dept_comp'>
        <DepartmentComp branchName={"cs"} items={getList("cs")} />
      </div>
      <div className='dept_comp'>
        <DepartmentComp branchName={"ee"}  items={getList("ee")} />
      </div>
      <div className='dept_comp'>
        <DepartmentComp branchName={"et"}  items={getList("et")} />
      </div>
      
      <div className='dept_comp'>
        <DepartmentComp branchName={"mech"}  items={getList("mech")} />
      </div>
    </div>
  )
}


export default DepartmentScreen