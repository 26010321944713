import React,{useState,useEffect} from 'react'
import firebase from '../base'
import moment from "moment";

function SyllabusComp({branchName, items }) {

    
  const branch = {
    "cs":"Computer Science Engineering",
    "ee":"Electrical Engineering",
    "civil":"Civil Engineering",
    "mech":"Mechanical Engineering",
    "et":"Electonics Telecommunication"
  }

    return (
        <div className='syllabus_comp'>
            <div className='syllabus_head'>
                <h4>{branch[branchName] }</h4>
                <h4></h4>
            </div>

            <div className='syllabus_content'>
                {
                    items?.map((item, index) => {
                        return (
                            <div className='syllabus_rows'>
                                <div className='video_left'>
                                    <p className='timestamp'> {moment(item?.timestamp).fromNow()}</p>
                                    <p>{item?.title}</p>

                                </div>
                                <p className='syllabus_download_link' onClick={()=>window.open(item?.link)}>View On Youtube</p>
                            </div>
                        )
                    })
                }

            </div>

        </div>
    )
}


function VideoScreen() {

    const [list, setlist] = useState([])
  const [loading, setloading] = useState(true)

  const getList = (e) => {
    const li = list?.filter((item)=>item?.department === e);
    return li
}

  useEffect(() => {

    const subscriber = firebase.firestore()
      .collection('video_links')
      .onSnapshot(querySnapshot => {
        const list_ = [];
        querySnapshot.forEach(doc => {

          list_.push({
            ...doc.data(),
            key: doc.id
          });
        });

        setlist(list_);
        setloading(false);
      });

    // Unsubscribe from blogs when no longer in use
    return () => subscriber();
  }, []);
    return (
        <div className='syllabus'>
            <SyllabusComp branchName="ee" items={getList("ee")} />
            <SyllabusComp branchName="et" items={getList("et")} />
            <SyllabusComp branchName="cs" items={getList("cs")} />
            <SyllabusComp branchName="civil" items={getList("civil")} />
            <SyllabusComp branchName="mech" items={getList("mech")} />
        </div>
    )
}

export default VideoScreen