import React, { useEffect ,useState} from 'react'
import firebase from "../base.js";

import moment from "moment";

function SyllabusComp({ items }) {

    const [list, setlist] = useState([])
    const [loading, setloading] = useState(true)

    
    useEffect(() => {

        const subscriber = firebase.firestore()
            .collection('notifications')
            .orderBy('time', 'desc')
            .onSnapshot(querySnapshot => {
                const list_ = [];
                querySnapshot.forEach(doc => {

                    list_.push({
                        ...doc.data(),
                        key: doc.id
                    });
                });

                setlist(list_);
                setloading(false);
            });

        // Unsubscribe from blogs when no longer in use
        return () => subscriber();
    }, []);

    return (
        <div className='syllabus_comp'>
            <div className='syllabus_head'>
                <h4>Notifications</h4>
                <h4></h4>
            </div>

            <div className='syllabus_content'>
                {
                    list?.map((item, index) => {
                        return (
                            <div className='syllabus_rows' key={index}>
                                {console.log(item)}
                                <h5>{moment(item?.time).fromNow()}<br /> <p>{item?.doc_name}</p> </h5>
                                <a href={item?.doc_link} download>Click Here</a>
                            </div>
                        )
                    })
                }

            </div>

        </div>
    )
}

function NotificationScreen() {
    return (
        <div className='syllabus'>
            <SyllabusComp  />
        </div>
    )
}


export default NotificationScreen