import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

const app = firebase.initializeApp({
    apiKey: "AIzaSyBC__m1e1uSaXelkule8xlHNuDJYGuvvjE",
    authDomain: "gpcdamoh-5eeda.firebaseapp.com",
    databaseURL: "https://gpcdamoh-5eeda.firebaseio.com",
    projectId: "gpcdamoh-5eeda",
    storageBucket: "gpcdamoh-5eeda.appspot.com",
    messagingSenderId: "73697265240",
    appId: "1:73697265240:web:33c8c4e1aa1f3908c86c2d",
    measurementId: "G-Q32L9RL9V7"
});



export default app;

