import React, { useState, useEffect } from 'react'
import firebase from "../base.js";
import { useHistory } from 'react-router-dom';

import img1 from "../assets/1.jpg";
import img2 from "../assets/2.jpg";
import img3 from "../assets/3.jpg";
import img4 from "../assets/4.jpg";
import img5 from "../assets/5.jpg";



function GalleryComp() {

    const history = useHistory()
    const [list, setlist] = useState([])
    const [loading, setloading] = useState(true)

    useEffect(() => {

        const subscriber = firebase.firestore()
            .collection('gallery')
            .orderBy("timestamp", "desc")
            .limit(6)
            .onSnapshot(querySnapshot => {
                const list_ = [];
                querySnapshot.forEach(doc => {

                    list_.push({
                        ...doc.data(),
                        key: doc.id
                    });
                });

                setlist(list_);
                setloading(false);
            });

        // Unsubscribe from blogs when no longer in use
        return () => subscriber();
    }, []);

    return (
        <div className='about_page'>

            <div className='about_box'>
                <div className='about_head'>
                    <p>Gallery</p>
                </div>

                <div className='gallery'>
                    {
                        list?.map((item,index)=>{
                            return(
                                <div className='gallery_comp'>
                                    <img src={item?.img} alt=""/>
                                </div>
                            )
                        })
                    }    

                    
                </div>
            </div>

            <div className='about_box'>
                <div className='about_head'>
                    <p>Importants Links</p>
                </div>

                <div className='alerts_rows'>
                    <p><i class="fa fa-asterisk"></i><a href='http://www.rgpvdiploma.in/'> RGPV Diploma</a> </p>
                    <p><i class="fa fa-asterisk"></i><a href='http://www.mponline.gov.in/'> MPONLINE</a> </p>
                    <p><i class="fa fa-asterisk"></i><a href='http://www.mptechedu.org/'> DTE</a> </p>
                    <p><i class="fa fa-asterisk"></i><a href='http://www.scholarshipportal.mp.nic.in/'> SCHLORSHIP</a> </p>
                    <p><i class="fa fa-asterisk"></i><a href='https://www.kopykitab.com/subdomain/rgpv2019/'> RGPV DIGITAL E-LIBRARY</a> </p>
                    <p><i class="fa fa-asterisk"></i><a href=' http://www.aicte-india.org/'> AICTE</a> </p>


                    {/* <p className="viewAll">View All</p> */}
                </div>

            </div>



        </div>
    )
}

export default GalleryComp