import React, { useState, useEffect } from 'react'
import firebase from "../base.js";
import { useHistory } from 'react-router-dom';
import moment from 'moment';

function AboutComp() {

    const history = useHistory()
    const [list, setlist] = useState([])
    const [loading, setloading] = useState(true)

    useEffect(() => {

        const subscriber = firebase.firestore()
            .collection('notifications')
            .orderBy('time', 'desc')
            .limit(6)
            .onSnapshot(querySnapshot => {
                const list_ = [];
                querySnapshot.forEach(doc => {

                    list_.push({
                        ...doc.data(),
                        key: doc.id
                    });
                });

                setlist(list_);
                setloading(false);
            });

        // Unsubscribe from blogs when no longer in use
        return () => subscriber();
    }, []);

    return (
        <div className='about_page'>

            <div className='about_box'>
                <div className='about_head'>
                    <p>Governemt Polytechnic College Damoh</p>
                </div>

                <div className='about_content'>
                    <p>To develop technical man power through excellent infrastructure in polytechnic institutions for social,
                        economic development of the state, to uplift the standard of living of people especially poor, scheduled
                        castes/scheduled tribes, minorities, women and PWDs. To provide training having low cost
                        effect/prepare skilled technicians and labourers those can get employment through formal and informal education/methods.
                        To add important contribution in providing the respectful employment to general public.</p>
                </div>

            </div>

            <div className='about_box'>
                <div className='about_head'>
                    <p>Recent Updates & Notifications</p>
                </div>

                <div className='alerts_rows'>
                    {
                        list.map((item, index) => {
                            return (

                                <div className='row_alerts'>

                                    <p key={index}>
                                        <i class="fa fa-asterisk"></i>
                                        <a href={item?.doc_link}> {item?.doc_name}</a>
                                    </p>

                                    <p>{moment(item?.time).fromNow()}</p>
                                </div>

                            )
                        })
                    }
                    <p onClick={() => history.push("/notifications")} className="viewAll">View All</p>
                </div>

            </div>



        </div>
    )
}

export default AboutComp