import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route } from "react-router-dom";
import HomeScreen from './pages/HomeScreen';
import HeaderComp from './comp/HeaderComp';
import FacilitiesScreen from './pages/FacilitiesScreen';
import FooterComp from './comp/FooterComp';
import SyllabusScreen from './pages/SyllabusScreen';
import DepartmentScreen from './pages/DepartmentScreen';
import VideoScreen from './pages/VideoScreen';
import GrievanceScreen from './pages/GrievanceScreen';
import NotificationScreen from './pages/NotificationScreen';
import AdmissionScreen from './pages/AdmissionScreen';


function App() {
  return (
    <Router>

      <div className="App">
        <HeaderComp />
        <Route exact path="/" component={HomeScreen} />
        <Route exact path="/facilities" component={FacilitiesScreen} />
        <Route exact path="/syllabus" component={SyllabusScreen} />
        <Route exact path="/department" component={DepartmentScreen} />
        <Route exact path="/videos" component={VideoScreen} />
        <Route exact path="/grievance" component={GrievanceScreen} />
        <Route exact path="/notifications" component={NotificationScreen} />
        <Route exact path="/admission" component={AdmissionScreen} />


        <FooterComp />
      </div>

    </Router>
  );
}

export default App;
